<body id="page-top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
        <div class="container px-4 px-lg-5">
            <a class="navbar-brand" href="#page-top"><img style="max-width: 50%; max-height: 250px;" src="../../../assets/logosis.png" alt=""></a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ms-auto my-2 my-lg-0">
                    <li class="nav-item"><a class="nav-link" href="#about">Nosotros</a></li>
                    <li class="nav-item"><a class="nav-link" href="#services">Servicios</a></li>
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Diseños</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contacto</a></li>
                    <li class="nav-item"><a class="nav-link" href="sisproteck-application">Aplicación</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead">
        <div class="container px-4 px-lg-5 h-100">
            <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                <div class="col-lg-8 align-self-end">
                    <h1 class="text-white font-weight-bold">¡Protege tu pasión por la carretera con estilo!</h1>
                    <hr class="divider" />
                </div>
                <div class="col-lg-8 align-self-baseline">
                    <p class="text-white-75 mb-5">🔥¡Presentamos la Chaqueta de Protección para Motociclistas, diseñada exclusivamente para ti, amante de la adrenalina y la seguridad en dos ruedas!🔥</p>
                    <a class="btn btn-primary btn-xl" href="#about">Conocenos</a>
                </div>
            </div>
        </div>
    </header>
    <!-- About-->
    <section class="page-section bg-primary" id="about">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8 text-center">
                    <h2 class="text-white mt-0">Amamos hacer chaquetas para todos los gustos.</h2>
                    <hr class="divider divider-light" />
                    <p class="text-white-75 mb-4">🛡️ Material de alta resistencia que proporciona protección contra impactos y abrasiones.
                        <br>🔒 Sistema de cierre ajustable que garantiza un ajuste cómodo y seguro.
                        <br>🌬️ Ventilación estratégica para mantenerte fresco en tus viajes más largos.
                        <br>📱 Bolsillos internos diseñados para dispositivos electrónicos y otros elementos esenciales.
                        <br>🌦️ Resistente al clima para mantenerte seco en cualquier condición.</p>
                    <a class="btn btn-light btn-xl" href="#services">Empecemos!</a>
                </div>
            </div>
        </div>
    </section>
    <!-- Services-->
    <section class="page-section" id="services">
        <div class="container px-4 px-lg-5">
            <h2 class="text-center mt-0">Nuestros Servicios</h2>
            <hr class="divider" />
            <div class="row gx-4 gx-lg-5">
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><img style="max-width: 50%;" src="../../../assets/jacket.png" alt=""></div>
                        <h3 class="h4 mb-2 text-primary">Chaquetas</h3>
                        <p class="text-muted mb-0">Creamos y Diseñamos Chaquetas Para Motociclistas.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><img style="max-width: 50%;" src="../../../assets/laptop.png" alt=""></div>
                        <h3 class="h4 mb-2 text-primary">Diseño Personalizado</h3>
                        <p class="text-muted mb-0">El Logo o Color Que Mas Te Guste.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><img style="max-width: 50%;" src="../../../assets/jeans.png" alt=""></div>
                        <h3 class="h4 mb-2 text-primary">Pantalones</h3>
                        <p class="text-muted mb-0">Creamos y Diseñamos Pantalones Para Motociclistas.</p>
                    </div>
                </div>
                <!--<div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><i class="bi-heart fs-1 text-primary"></i></div>
                        <h3 class="h4 mb-2">Made with Love</h3>
                        <p class="text-muted mb-0">Is it really open source if it's not made with love?</p>
                    </div>
                </div>-->
            </div>
        </div>
    </section>
    <!-- Portfolio-->
    <div id="portfolio">
        <div class="container px-4 px-lg-5 text-center">            
            <a class="btn btn-primary btn-xl" href="sisproteck-designs">Ver Chaquetas</a>
            <a class="btn btn-primary btn-xl" href="sisproteck-hoodies">Ver Busos</a>
        </div>     
        <br>   
        <div class="container-fluid p-0">
            <div class="row g-0">
                <div class="col-lg-4 col-sm-6">
                    <a class="portfolio-box" target="_blank" href="assets/img/portfolio/fullsize/1.png" title="Project Name">
                        <img class="img-fluid" target="_blank" src="assets/img/portfolio/thumbnails/1.png" alt="..." />
                        <div class="portfolio-box-caption">
                            <div class="project-category text-white-50">Diseño</div>
                            <div class="project-name">Personalizado</div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <a class="portfolio-box" target="_blank" href="assets/img/portfolio/fullsize/2.png" title="Project Name">
                        <img class="img-fluid" target="_blank" src="assets/img/portfolio/thumbnails/2.png" alt="..." />
                        <div class="portfolio-box-caption">
                            <div class="project-category text-white-50">Diseño</div>
                            <div class="project-name">Personalizado</div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <a class="portfolio-box" target="_blank" href="assets/img/portfolio/fullsize/3.png" title="Project Name">
                        <img class="img-fluid" target="_blank" src="assets/img/portfolio/thumbnails/3.png" alt="..." />
                        <div class="portfolio-box-caption">
                            <div class="project-category text-white-50">Diseño</div>
                            <div class="project-name">Personalizado</div>
                        </div>
                    </a>
                </div>
                <!--<div class="col-lg-4 col-sm-6">
                    <a class="portfolio-box" href="assets/img/portfolio/fullsize/4.jpg" title="Project Name">
                        <img class="img-fluid" src="assets/img/portfolio/thumbnails/4.jpg" alt="..." />
                        <div class="portfolio-box-caption">
                            <div class="project-category text-white-50">Category</div>
                            <div class="project-name">Project Name</div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <a class="portfolio-box" href="assets/img/portfolio/fullsize/5.jpg" title="Project Name">
                        <img class="img-fluid" src="assets/img/portfolio/thumbnails/5.jpg" alt="..." />
                        <div class="portfolio-box-caption">
                            <div class="project-category text-white-50">Category</div>
                            <div class="project-name">Project Name</div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <a class="portfolio-box" href="assets/img/portfolio/fullsize/6.jpg" title="Project Name">
                        <img class="img-fluid" src="assets/img/portfolio/thumbnails/6.jpg" alt="..." />
                        <div class="portfolio-box-caption p-3">
                            <div class="project-category text-white-50">Category</div>
                            <div class="project-name">Project Name</div>
                        </div>
                    </a>
                </div>-->
            </div>
        </div>
    </div>
    <!-- Call to action-->
    <section class="page-section bg-dark text-white">
        <div class="container px-4 px-lg-5 text-center">
            <h2 class="mb-4">Escribenos como quieres tu chaqueta!</h2>
            <a class="btn btn-light btn-xl" href="https://wa.link/bsxhjo">Personaliza tu Chaqueta</a>
        </div>
    </section>
    <!-- Contact-->
    <section class="page-section" id="contact">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8 col-xl-6 text-center">
                    <h2 class="mt-0">Hablemos!</h2>
                    <hr class="divider" />
                    <p class="text-muted mb-5">¡No comprometas tu seguridad ni tu estilo! Contáctanos hoy mismo para obtener más información sobre cómo adquirir tu propia Chaqueta de Protección para Motociclistas.</p>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5 justify-content-center mb-5">
                <div class="col-lg-6">
                    <!-- * * * * * * * * * * * * * * *-->
                    <!-- * * SB Forms Contact Form * *-->
                    <!-- * * * * * * * * * * * * * * *-->
                    <!-- This form is pre-integrated with SB Forms.-->
                    <!-- To make this form functional, sign up at-->
                    <!-- https://startbootstrap.com/solution/contact-forms-->
                    <!-- to get an API token!-->
                </div>
            </div>
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-4 text-center mb-5 mb-lg-0">
                    <a href="mailto:sisproteckmotos@gmail.com"><i class="bi-envelope fs-2 mb-3 text-muted"></i>
                        <div>sisproteckmotos@gmail.com</div></a>
                    
                </div>
                <div class="col-lg-4 text-center mb-5 mb-lg-0">
                    <a href="tel:+57 321 414 15 06"><i class="bi-phone fs-2 mb-3 text-muted"></i>
                        <div>+57 321 414 15 06</div></a>
                </div>
                <div class="col-lg-4 text-center mb-5 mb-lg-0">
                    <a href="https://wa.link/bsxhjo"><i class="bi-whatsapp fs-2 mb-3 text-muted"></i>
                        <div>+57 321 414 15 06</div></a>
                </div>                
            </div>
        </div>
    </section>
    <!-- Footer-->
    <footer class="bg-light py-5">
        <div class="container px-4 px-lg-5"><div class="small text-center text-muted">Copyright &copy; 2023 - Sisproteck Motos</div></div>
    </footer>
    <!-- Bootstrap core JS-->
    </body>
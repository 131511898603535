import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  private Content_Type = 'application/json';
  url_auth = environment.api_delete_authentication;

  constructor(private httpClient: HttpClient) { }


  /**
* delete
*/
  public delete(body) {
    let headers = new HttpHeaders()
    .set("Content-Type", this.Content_Type);    
    return this.httpClient.post(
      this.url_auth + "delete",
      body
    );
  }
}

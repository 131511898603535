import { Component, OnInit } from '@angular/core';
import { GetService } from 'src/app/services/get.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  logins = true;
  clientes = false;
  pedidos = true;
  lists: boolean;
  user: string = "";
  pass: string;
  type = "Admin";

  constructor(private getservice:GetService, private notification:NotificationsService) {
    //localStorage.clear();
  }

  ngOnInit(): void {
    console.log(this.logins);
    //this.mostrar();
    if(localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== 'null' && localStorage.getItem('user') !== 'undefined' ){
      this.logins = false;
      this.type = localStorage.getItem('user');
    }
  }

  /**
   * mostrar
   */
  public mostrar() {
    console.log("mostrar");

    document.getElementById("")
    this.logins = true;
    if (this.user === 'ecle' && this.pass === "314") {
      this.logins = false;
      localStorage.setItem('user', 'Admin');
    } else if (this.user !== "ecle") {
      this.getDatos();
    } else {
      this.notification.error("Usuario o Contraseña Incorrecta.");
    }

  }

  /**
   * verClientes
   */
  public verClientes() {
    this.pedidos = false;
    this.clientes = true;
    this.lists = false;
  }

  /**
 * verPedidos
 */
  public verPedidos() {
    this.pedidos = true;
    this.clientes = false;
    this.lists = false;
  }

  /**
* verPedidos
*/
  public verListas() {
    this.pedidos = false;
    this.clientes = false;
    this.lists = true;
  }

  /**
   * getDatos
   */
  public getDatos() {
    let body = {
      "collection": "customers",
      "id": this.pass
    }
    this.getservice.get(body).subscribe((res:any)=>{
      if(res.success === true){
        let d1 = this.user.replace(" ", "");
        let d2 = res.response[0].phone.replace(" ", "");
        if(d1.trim() === (d2).trim()){
          this.logins = false;
          localStorage.setItem('user', 'User');
          this.type = "User";
          localStorage.setItem('data',res.response[0].name);
        }else{
          this.notification.error("Usuario o Contraseña Incorrecta.");
        }
      }else{
        this.notification.error("Por favor intenta mas tarde.");
      }
    })
  }

}

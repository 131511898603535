import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent implements OnInit {
  id: any;
  customers = [];
  e0 = false;
  e1 = false;
  e2 = false;
  e3 = false;
  e4 = false;
  e5 = false;

  constructor(private getservice:GetService, private activatedRoute:ActivatedRoute) {


    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      this.id = params.params.id;
    });
   }

  ngOnInit(): void {
    this.getRequests();
  }


      /**
   * getRequests
   */
      public getRequests() {
        let body = {
          "collection": "requests",
          "id": this.id
        }
        this.getservice.get(body).subscribe((res:any)=>{
          this.customers = res.response;
          console.log(this.customers);

          if(this.customers[0].state === 'Registrado'){
            document.getElementById('0').click();
            this.e1 = true;
            this.e2 = true;
            this.e3 = true;
            this.e4 = true;
          }else if(this.customers[0].state === 'Recibido'){
            document.getElementById('1').click();
            this.e0 = true;
            this.e2 = true;
            this.e3 = true;
            this.e4 = true;
            this.e5 = true;
          }else if(this.customers[0].state === 'En Corte'){
            document.getElementById('2').click();
            this.e0 = true;
            this.e1 = true;
            this.e3 = true;
            this.e4 = true;
            this.e5 = true;
          }else if(this.customers[0].state === 'Bordando'){
            document.getElementById('3').click();
            this.e0 = true;
            this.e1 = true;
            this.e2 = true;
            this.e4 = true;
            this.e5 = true;
          }else if(this.customers[0].state === 'En Confeccion'){
            document.getElementById('4').click();
            this.e0 = true;
            this.e1 = true;
            this.e2 = true;
            this.e3 = true;
            this.e5 = true;
          }else if(this.customers[0].state === 'Terminada Programar Entrega'){
            document.getElementById('5').click();
            this.e0 = true;
            this.e1 = true;
            this.e2 = true;
            this.e3 = true;
            this.e4 = true;
          }
        })
      }

}

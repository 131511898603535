import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeleteService } from 'src/app/services/delete.service';
import { GetService } from 'src/app/services/get.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UpdateService } from 'src/app/services/update.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {
  customers = []
  ver: boolean;
  constructor(private notification:NotificationsService,
              private updateservice:UpdateService,
              private getservice:GetService, 
              private routEx:Router, 
              private deleteservice:DeleteService,) { }

  ngOnInit(): void {
    this.getRequests();
    console.log("lists")
  }

  /**
   * search
   */
  public search(word) {
    if(word == ""){
      this.getRequests();
    }

    var filteredArray = this.customers.filter((str)=>{
      return str.customer.toLowerCase().indexOf(word.toLowerCase()) >= 0; 
    });
    console.log(filteredArray);
    this.customers = filteredArray;
  }

    /**
   * getRequests
   */
    public getRequests() {
      let body = {
        "collection": "requests",
        "id": ""
      }
      this.getservice.get(body).subscribe((res:any)=>{
        this.customers = res.response;
        if(localStorage.getItem('user') === 'User'){
          let user:any = localStorage.getItem('data');
          let datos = [];

          for (let i = 0; i < this.customers.length; i++) {
            if(this.customers[i].customer === user && this.customers[i].deleted === false){
              datos.push(this.customers[i])
            }
            
          }

          this.customers = datos;

      

        }else{
          let datos = [];

          for (let i = 0; i < this.customers.length; i++) {
            if(this.customers[i].deleted === false){
              datos.push(this.customers[i])
            }
            
          }

          this.customers = datos;
          this.ver = true;
        }
      })
    }

    /**
     * consultar
     */
    public consultar(id) {
      console.log("consulto");
      localStorage.setItem('ids',id);
      this.routEx.navigate(['sisproteck-review'])

    }

    /**
     * delete
     */
    public delete(id) {
      let body = {
        "collection": "requests",
        "id": [id],
        "body":{deleted:true}
      }


      this.notification.delete("La solicitud se borrara del listado de pedidos.").then((res:any)=>{
        if(res === true){
          this.updateservice.update(body).subscribe((res:any)=>{
            console.log(res);
            if (res.success === true) {          
              this.notification.success("La solicitud se ha borrado exitosamente.");
              this.getRequests();
            } else {
             this.notification.error("Error en el borrado, intente de nuevo.");
            }
          })
        }else{

        }
      })
      
     
    }
  

    /**
     * changes
     */
    public changes(state, id) {
      let body = {
        "collection": "requests",
        "id": [id],
        "body":{state:state}
      }
      this.updateservice.update(body).subscribe((res:any)=>{
        console.log(res);
        if (res.success === true) {
          this.notification.success("La solicitud se ha actualizado exitosamente.");
          this.getRequests();
        } else {
         this.notification.error("Error en la actualizacion, intente de nuevo.");
        }
      })
    }

    /**
     * views
     */
    public views(id) {
      window.open('https://sisproteckmotos.co/sisproteck-steps?id='+id)
    }

}

  <div class="container">
    <h2>Clientes Registrados</h2>
    <div class="row">
      <div class="col-8">
        <input (input)="search($event.target.value)" class="form-control" id="demo" type="text" placeholder="Buscar Cliente">    
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-primary">Crear Cliente Nuevo</button>
      </div>
    </div>
    <br>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>Telefono</th>
          <th>Correo</th>
        </tr>
      </thead>
      <tbody id="test">
        <tr *ngFor="let customer of customers; let i=index">
          <td>{{customer._id}}</td>
          <td>{{customer.name}}</td>
          <td>{{customer.phone}}</td>
          <td>{{customer.email}}</td>
        </tr>
      </tbody>
    </table>
  </div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Cliente</h5>
        <button id="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="name" class="form-label">Nombre</label>
            <input type="text" class="form-control" id="name" formControlName="name" required>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Teléfono</label>
            <input type="tel" class="form-control" id="phone" formControlName="phone" required>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Correo Electrónico</label>
            <input type="email" class="form-control" id="email" formControlName="email" required>
          </div>
          <button type="submit" class="btn btn-primary">Guardar Cliente</button>
        </form>
      </div>      
    </div>
  </div>
</div>
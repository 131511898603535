<div class="container">
    <img src="../../../assets/logosis.png" alt="">
    <div class="accordion" id="accordionExample">
        <hr style="color: #f4623a;">
        Aqui podrás realizar un seguimiento detallado de tus pedidos en tiempo real. ¡Estamos aquí para brindarte la
        mejor experiencia de compra!
        <hr style="color: #f4623a;">
        <div class="steps">
            <progress id="progress" value=0 max=100></progress>
            <div class="step-item col-sm">
                <button [disabled]="e0"  id="0" class="step-button text-center" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseZero" aria-expanded="false" aria-controls="collapseZero">
                    1
                </button>
                <div class="step-title">
                    Pedido Registrado
                </div>
            </div>
            <div class="step-item col-sm">
                <button [disabled]="e1"  id="1" class="step-button text-center" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    1
                </button>
                <div class="step-title">
                    Pedido Recibido
                </div>
            </div>
            <div class="step-item col-sm">
                <button [disabled]="e2"  id="2"  class="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    2
                </button>
                <div class="step-title">
                    Pedido en Corte
                </div>
            </div>
            <div class="step-item col-sm">
                <button [disabled]="e3"  id="3"  class="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    3
                </button>
                <div class="step-title">
                    Pedido En Bordado
                </div>
            </div>
            <!---->
            <div class="step-item col-sm">
                <button [disabled]="e4"  id="4"  class="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    4
                </button>
                <div class="step-title">
                    Pedido En Confeccion
                </div>
            </div>
            <!---->
            <div class="step-item col-sm">
                <button [disabled]="e5"  id="5" class="step-button text-center collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    5
                </button>
                <div class="step-title">
                    Pedido Terminado
                </div>
            </div>
        </div>

        <div class="card col-sm">
            <div id="headingZero">
            </div>

            <div id="collapseZero" class="collapse" aria-labelledby="headingZero" >
                <div class="card-body">
                    Tu pedido ha sido registrado con éxito. Nos encontramos en la etapa inicial del proceso y pronto comenzaremos a trabajar en tu chaqueta personalizada. Te mantendremos informado sobre el progreso. Gracias por confiar en nosotros.
                </div>
            </div>
        </div>

        <div class="card col-sm">
            <div id="headingOne">
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" >
                <div class="card-body">
                    ¡Gracias por tu pedido de chaqueta en Sisprotek! Estamos encantados de informarte que hemos recibido tu
                    solicitud. Estamos trabajando en tu chaqueta y te mantendremos informado sobre su estado. ¡Gracias
                    por confiar en nosotros!
                </div>
            </div>
        </div>
        <div class="card col-sm">
            <div id="headingTwo">

            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" >
                <div class="card-body">
                    Queremos informarte que tu chaqueta para moto ha entrado en la fase de corte.
                    Nuestro equipo está trabajando en darle forma a tu prenda de acuerdo a tus especificaciones.
                    Mantente atento a futuras actualizaciones. ¡Gracias por elegirnos!
                </div>
            </div>
        </div>
        <div class="card col-sm">
            <div id="headingThree">

            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" >
                <div class="card-body">
                    ¡Buenas noticias! Tu chaqueta ha avanzado a la etapa de bordado. Nuestros especialistas
                    están aplicando los detalles personalizados a tu prenda. Te mantendremos al tanto de su progreso.
                    ¡Gracias por confiar en nosotros para tu chaqueta única!
                </div>
            </div>
        </div>
        <!---->
        <div class="card col-sm">
            <div id="headingFour">

            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" >
                <div class="card-body">
                    ¡Estamos emocionados de decirte que tu chaqueta para moto se encuentra actualmente en la fase de
                    confección. Nuestro equipo está trabajando duro para crear la prenda perfecta para ti. ¡Muy pronto
                    la tendrás en tus manos! Gracias por elegirnos para tu chaqueta personalizada.
                </div>
            </div>
        </div>
        <!---->
        <div class="card col-sm">
            <div id="headingFive">

            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" >
                <div class="card-body">
                    ¡Felicidades! Tu chaqueta ha sido completada y está lista para ser entregada. Para
                    programar la entrega y recibir tu prenda, por favor, contáctanos. Estamos ansiosos por que tengas en tus manos tu nueva chaqueta. ¡Gracias por elegirnos!
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hoodies',
  templateUrl: './hoodies.component.html',
  styleUrls: ['./hoodies.component.css']
})
export class HoodiesComponent implements OnInit {

  designs = [
    { "image": "../../../assets/bs/1.png" },
    { "image": "../../../assets/bs/2.png" },
    { "image": "../../../assets/bs/3.png" },
    { "image": "../../../assets/bs/4.png" },
    { "image": "../../../assets/bs/5.png" },
    { "image": "../../../assets/bs/6.png" },
    { "image": "../../../assets/bs/7.png" },
    { "image": "../../../assets/bs/8.png" },
    { "image": "../../../assets/bs/9.png" },
    { "image": "../../../assets/bs/10.png" },
    { "image": "../../../assets/bs/11.png" },
    { "image": "../../../assets/bs/12.png" },
    { "image": "../../../assets/bs/13.png" },
    { "image": "../../../assets/bs/14.png" },
    { "image": "../../../assets/bs/15.png" },
    { "image": "../../../assets/bs/16.png" },
    { "image": "../../../assets/bs/17.png" },
    { "image": "../../../assets/bs/18.png" },
    { "image": "../../../assets/bs/19.png" },
    { "image": "../../../assets/bs/20.png" },
    { "image": "../../../assets/bs/21.png" },
    { "image": "../../../assets/bs/22.png" },
    { "image": "../../../assets/bs/23.png" },
    { "image": "../../../assets/bs/24.png" },
    { "image": "../../../assets/bs/25.png" },
    { "image": "../../../assets/bs/26.png" },
    { "image": "../../../assets/bs/27.png" },
    { "image": "../../../assets/bs/28.png" },
    { "image": "../../../assets/bs/29.png" },
    { "image": "../../../assets/bs/30.png" },
    { "image": "../../../assets/bs/31.png" },
    { "image": "../../../assets/bs/32.png" },
    { "image": "../../../assets/bs/33.png" },
    { "image": "../../../assets/bs/34.png" },
    { "image": "../../../assets/bs/35.png" },
    { "image": "../../../assets/bs/36.png" },
    { "image": "../../../assets/bs/37.png" },
    { "image": "../../../assets/bs/38.png" },
    { "image": "../../../assets/bs/39.png" },
    { "image": "../../../assets/bs/40.png" },
    { "image": "../../../assets/bs/41.png" },
    { "image": "../../../assets/bs/42.png" },
    { "image": "../../../assets/bs/43.png" },
    { "image": "../../../assets/bs/44.png" },
    { "image": "../../../assets/bs/45.png" },
    { "image": "../../../assets/bs/46.png" }
];


  constructor() { }

  ngOnInit(): void {
  }

}

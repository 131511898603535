import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateService } from 'src/app/services/create.service';
import { GetService } from 'src/app/services/get.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  customers = [];
  clientForm: FormGroup;
  constructor(private create_service: CreateService,private getservice:GetService,private fb: FormBuilder,private notification:NotificationsService) {

    this.clientForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
   }

  ngOnInit(): void {
    console.log("customers")
    this.getCustomers();
  }

  /**
   * filter
   */
  public filter() {
    
  }

  onSubmit() {
    if (this.clientForm.valid) {
      const formData = this.clientForm.value;
      console.log('Nombre:', formData.name);
      console.log('Teléfono:', formData.phone);
      console.log('Correo Electrónico:', formData.email);

      // Opcional: Aquí puedes enviar formData a tu servicio o API

      let body = {
        "collection":"customers",
        body:formData
      }
      
      this.create_service.create(body).subscribe((res:any)=>{
        console.log("response", res);
        if(res.success === true){
          if(res.response.acknowledged){
            //this.notification.error("Pedido registrado Correctamente.");
            this.notification.success("Cliente registrado Correctamente.");
            this.getCustomers();
          }else{
            this.notification.error("No se logro registrar el cliente, intenta de nuevo..");
            
          }
        }else{
          this.notification.error("No se logro registrar el cliente, intenta de nuevo..");
          
        }
        
      })

      // Limpiar el formulario
      this.clientForm.reset();
      // Cierra el modal (suponiendo que estás usando Bootstrap)
      document.getElementById('closeModal').click();
    }
  }


  /**
   * search
   */
  public search(word) {

    if(word == ""){
      this.getCustomers();
    }

    var filteredArray = this.customers.filter((str)=>{
      return str.name.toLowerCase().indexOf(word.toLowerCase()) >= 0; 
    });
    console.log(filteredArray);
    this.customers = filteredArray;
  }


  /**
   * getCustomers
   */
  public getCustomers() {
    let body = {
      "collection": "customers",
      "id": ""
    }
    this.getservice.get(body).subscribe((res:any)=>{
      this.customers = res.response;
    })
  }

}

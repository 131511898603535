<div id="portfolio">
    <img src="../../../assets/logosis.png" alt="">
    <br>
    <a style="margin: 10px;" class="btn btn-primary" href="sisproteck-site">Inicio</a>
    <br>
    <h3>Escoge tu Estilo</h3>
    <div class="container-fluid p-0">
        <div class="row g-0">
            <div class="col-lg-4 col-sm-6" *ngFor="let desig of designs;">
                <a class="portfolio-box" target="_blank" href="{{desig.image}}" title="Project Name">
                    <img style="max-height: 350px; max-width: 350px;" class="img-fluid" target="_blank" src="{{desig.image}}" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Diseño</div>
                        <div class="project-name">Personalizado</div>
                    </div>
                </a>
            </div>
            <!--<div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/4.jpg" title="Project Name">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/4.jpg" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Category</div>
                        <div class="project-name">Project Name</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/5.jpg" title="Project Name">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/5.jpg" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Category</div>
                        <div class="project-name">Project Name</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/6.jpg" title="Project Name">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/6.jpg" alt="..." />
                    <div class="portfolio-box-caption p-3">
                        <div class="project-category text-white-50">Category</div>
                        <div class="project-name">Project Name</div>
                    </div>
                </a>
            </div>-->
        </div>
    </div>
</div>
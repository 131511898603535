import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { ApplicationComponent } from './pages/application/application.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { HttpClientModule } from '@angular/common/http';
import { ListsComponent } from './pages/lists/lists.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewComponent } from './pages/review/review.component';
import { DesingsComponent } from './pages/desings/desings.component';
import { StepsComponent } from './pages/steps/steps.component';
import { HoodiesComponent } from './pages/hoodies/hoodies.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ApplicationComponent,
    CustomersComponent,
    RequestsComponent,
    ListsComponent,
    ReviewComponent,
    DesingsComponent,
    StepsComponent,
    HoodiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

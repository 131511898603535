import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateService } from 'src/app/services/create.service';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  registerForm: any;
  models = [];
  photo = "";
  des = "";
  verlogos = false;

  constructor(private formBuilder: FormBuilder,
    private create_service: CreateService,
    private getservice: GetService,private _routEx:Router) {
    this.registerForm = this.formBuilder.group({
      customer: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      model: ["", [Validators.required]],
      colorpri: ["", [Validators.required]],
      colorsec: ["", [Validators.required]],
      colorter: ["", []],
      types: ["", [Validators.required]],
      talla: ["", []],
      cant: [1, []],
      hombro: ["", []],
      brazo: ["", []],
      pecho: ["", []],
      contorno: ["", []],
      largo: ["", []],
      cadera: ["", []],
      logos: ["", []],
      //LOGOS
      logojiba: ["", []],
      logomanga: ["", []],
      logofrente: ["", []],
      logoespalda: ["", []],
      logopantalon:["",[]],
      logorh: ["", []],
      logonombre: ["", []],
      logojibaimagen: ["", []],
      logomangaimagen: ["", []],
      logofrenteimagen: ["", []],
      logoespaldaimagen: ["", []],
      logopantalonimagen:["",[]],
      logorhtext: ["", []],
      logonombretext: ["", []],
      logorhlocation: ["", []],
      logonombrelocation: ["", []],
      observations: ["", []]

    });
   }

  ngOnInit(): void {
    console.log("localStorage.getItem('ids');", localStorage.getItem('ids'));
    
    this.getClientes();
  }

  tallas = false;
  medidas = false;
  logo1: string;
  logo2: string;
  logo3: string;
  logo4: string;
  logo5: string;

  /**
   * getClientes
   */
  public getClientes() {
    let body = {
      "collection": "requests",
      "id": localStorage.getItem('ids')
    }
    this.getservice.get(body).subscribe((res:any)=>{
      if(res.response.length >0){

        let dato = res.response[0];

        this.registerForm.controls.customer.setValue(dato.customer);
        this.registerForm.controls.email.setValue(dato.email);
        this.registerForm.controls.phone.setValue(dato.phone);
        this.registerForm.controls.model.setValue(dato.model);
        this.registerForm.controls.colorpri.setValue(dato.colorpri);
        this.registerForm.controls.colorsec.setValue(dato.colorsec);
        this.registerForm.controls.colorter.setValue(dato.colorter);

        this.registerForm.controls.types.setValue(dato.types);
        this.change1(dato.types);
        this.registerForm.controls.talla.setValue(dato.talla);
        this.registerForm.controls.cant.setValue(dato.cant);

        this.registerForm.controls.hombro.setValue(dato.hombro);
        this.registerForm.controls.brazo.setValue(dato.brazo);
        this.registerForm.controls.pecho.setValue(dato.pecho);
        this.registerForm.controls.contorno.setValue(dato.contorno);
        this.registerForm.controls.largo.setValue(dato.largo);
        this.registerForm.controls.cadera.setValue(dato.cadera);
        this.registerForm.controls.logos.setValue(dato.logos);
        this.change2(dato.logos);

        //LOGOS
        this.registerForm.controls.logojiba.setValue(dato.logojiba);
        this.registerForm.controls.logomanga.setValue(dato.logomanga);
        this.registerForm.controls.logofrente.setValue(dato.logofrente);
        this.registerForm.controls.logoespalda.setValue(dato.logoespalda);
        this.registerForm.controls.logopantalon.setValue(dato.logopantalon);
        this.registerForm.controls.logopantalonimagen.setValue(dato.logopantalonimagen);
        this.registerForm.controls.logorh.setValue(dato.logorh);
        this.registerForm.controls.logonombre.setValue(dato.logonombre);

        //IMAGES


        this.logo1 = (dato.logojibaimagen);
        this.logo2 = (dato.logomangaimagen);
        this.logo3 = (dato.logofrenteimagen);
        this.logo4 = (dato.logoespaldaimagen);

        this.registerForm.controls.logojibaimagen.setValue(dato.logojibaimagen);
        this.registerForm.controls.logomangaimagen.setValue(dato.logomangaimagen);
        this.registerForm.controls.logofrenteimagen.setValue(dato.logofrenteimagen);
        this.registerForm.controls.logoespaldaimagen.setValue(dato.logoespaldaimagen);
        this.registerForm.controls.logorhtext.setValue(dato.logorhtext);
        this.registerForm.controls.logonombretext.setValue(dato.logonombretext);
        this.registerForm.controls.logorhlocation.setValue(dato.logorhlocation);
        this.registerForm.controls.logonombrelocation.setValue(dato.logonombrelocation);

        this.registerForm.controls.observations.setValue(dato.observations);

        this.getModels();

      }
    })
  }


    /**
   * change1
   */
    public change1(value) {
      if(value === 'Tallaje'){
        this.tallas = true;
        this.medidas = false;
      }
  
      if(value === 'Medidas'){
        this.tallas = false;
        this.medidas = true;
      }
  
      if(value === 'Curva'){
        this.tallas = false;
        this.medidas = false;
      }
    }

        /**
   * change1
   */
        public change2(value) {
          if(value === 'Personalizado'){
            this.verlogos = true;
            
          }else{
            this.verlogos = false;
          }
      
         
        }

    /**
     * imprimir
     */
    public imprimir(form) {
      console.log("Imprimir")
      let printContents = document.getElementById('cmpName').innerHTML;
     //let originalContents = document.body.innerHTML;

     //document.body.innerHTML = printContents;

     window.print();

     //document.body.innerHTML = originalContents;
    }

    /**
     * back
     */
    public back() {
      //window.location.href = '/sisproteck-application';
      this._routEx.navigate(['sisproteck-application'])
    }

        /**
   * getModels
   */
        public getModels() {
          let body = {
            "collection": "models",
            "id": ""
          }
          this.getservice.get(body).subscribe((res:any)=>{
            this.models = res.response;
            this.changemodel(this.registerForm.controls.model.value);
          })
        }

        /**
   * changemodel
   */
        public changemodel(value) {
          console.log("changes")
          for (let i = 0; i < this.models.length; i++) {
          
              if(this.models[i].name === value ){
               this.photo = this.models[i].image;
                this.des =  this.models[i].description;
              }
            
          }
        }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { ApplicationComponent } from './pages/application/application.component';
import { ReviewComponent } from './pages/review/review.component';
import { DesingsComponent } from './pages/desings/desings.component';
import { StepsComponent } from './pages/steps/steps.component';
import { HoodiesComponent } from './pages/hoodies/hoodies.component';

const routes: Routes = [{
  path: '',
  redirectTo: 'sisproteck-site',
  pathMatch: 'full',
}, {
  path: 'sisproteck-site',
  component: MainComponent
}, {
  path: 'sisproteck-application',
  component: ApplicationComponent
},{
  path: 'sisproteck-review',
  component:ReviewComponent
},{
  path: 'sisproteck-designs',
  component:DesingsComponent
},{
  path: 'sisproteck-steps',
  component:StepsComponent
},{
  path: 'sisproteck-hoodies',
  component:HoodiesComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

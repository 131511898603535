import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() {
   
  }

  /**
   * success
   */
  public success(message) {
    swal(message, '', 'success')
  }

  /**
   * error
   */
  public error(message) {
    swal(message, '', 'error')
  }

  /**
   * delete
   */
  public delete(message) {
   return swal({
      title: "Esta seguro?",
      text: message,
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",   
    })
    .then((willDelete) => {

        if(willDelete.value){
             return true;
        }else{
          return false;
        }
    });
  }

}

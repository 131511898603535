import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  private Content_Type = 'application/json';
  url_auth = environment.api_create_authentication;

  constructor(private httpClient: HttpClient) { }


  /**
* create
*/
  public create(body) {
    let headers = new HttpHeaders()
    .set("Content-Type", this.Content_Type)
    return this.httpClient.post(
      this.url_auth + "create",
      body,
      { headers: headers }
    );
  }
}

<div class="container">
  <h2>Pedidos Registrados</h2>
  <div class="row">
    <div class="col-8">
      <input (input)="search($event.target.value)" class="form-control" id="demo" type="text"
        placeholder="Buscar Cliente">
    </div>
  </div>
  <br>
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th *ngIf="ver">Estados</th>
        <th>Acciones</th>
        <th>Cliente</th>
        <th>Modelo</th>
        <th>Fecha Registro</th>
        <th>Estado Pedido</th>
        <th>Observaciones</th>
      </tr>
    </thead>
    <tbody id="test">
      <tr *ngFor="let customer of customers; let i=index">
        <td *ngIf="ver">
          <button class="btn btn-outline-primary" (click)="changes('Recibido', customer._id)">Recibido</button><br>
          <button class="btn btn-outline-primary" (click)="changes('En Corte', customer._id)">En Corte</button><br>
          <button class="btn btn-outline-primary" (click)="changes('Bordando', customer._id)">Bordando</button><br>
          <button class="btn btn-outline-primary" (click)="changes('En Confeccion', customer._id)">En Confeccion</button><br>
          <button class="btn btn-outline-primary" (click)="changes('Terminada Programar Entrega', customer._id)">Terminada Programar Entrega</button>
        </td>
        <td>
          <button (click)="consultar(customer._id)" class="btn btn-primary m-1">consultar</button>
          <button (click)="delete(customer._id)" class="btn btn-primary m-1">Borrar</button>
          <button (click)="views(customer._id)" class="btn btn-primary m-1">Seguimiento</button>
        </td>
        <td>{{customer.customer}}</td>
        <td>{{customer.model}}</td>
        <td>{{customer.createdAt}}</td>
        <td [ngClass]="{
          'estado-recibido': customer.state === 'Recibido',
          'estado-en-corte': customer.state === 'En Corte',
          'estado-bordando': customer.state === 'Bordando',
          'estado-en-confeccion': customer.state === 'En Confeccion',
          'estado-terminada': customer.state === 'Terminada Programar Entrega'
        }">
          {{customer.state}}
        </td>
        <td>{{customer.observations}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="logins">
    <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
            <img style="width: 50%;" src="../../../assets/logosis.png" alt="">
            <h4>Bienvenido al sistema Sisproteck Motos</h4>
            <form>
                <div class="form-group">
                    <label for="exampleInputEmail1"><strong>Usuario</strong></label>
                    <input [(ngModel)]="user" name="user" type="email" class="form-control" aria-describedby="emailHelp"
                        placeholder="Usuario">
                    <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <br>
                <div class="form-group">
                    <label for="exampleInputPassword1"><strong>Contraseña</strong></label>
                    <input name="pass" [(ngModel)]="pass" type="password" class="form-control" placeholder="Contraseña">
                </div>
                <br>
                <button style="width: 100px;" (click)="mostrar()" class="btn btn-primary btn-block">Iniciar</button>
            </form>
        </div>
        <div class="col-lg-4"></div>

        
        
    </div>

    <br>

    <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4"><div class="alert alert-warning" role="alert">
            <strong>Importante: </strong> Explora nuestra colección de hoodies para motociclistas y descubre cómo puedes destacar en la carretera con elegancia. Haz clic en el botón a continuación y lleva tu estilo al siguiente nivel.
            <br>
            <br>

            <a class="btn btn-primary btn-xl" href="sisproteck-hoodies">Ver Mas</a>
            
          </div></div>
        <div class="col-lg-4"></div>
    </div>

</div>

<div class="row" *ngIf="!logins">
    <hr>
    <div class="col-lg-4"></div>
    <div class="col-lg-4"><button *ngIf="type == 'User' || type == 'Admin'" (click)="verPedidos()" class="btn btn-primary">Nuevo Pedido</button> -- <button *ngIf="type == 'Admin'"
            (click)="verClientes()" class="btn btn-primary">Clientes</button> -- <button *ngIf="type == 'User' || type == 'Admin'" (click)="verListas()"
            class="btn btn-primary">Pedidos</button></div>
    <div class="col-lg-4"><a style="float: right;margin-right: 20px;" href="#">Cerrar sesión</a></div>
</div>
<hr>
<div class="row" *ngIf="!logins" style="margin: 20px;">
    <app-requests *ngIf="pedidos"></app-requests>
    <app-customers *ngIf="clientes"></app-customers>
    <app-lists *ngIf="lists"></app-lists>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateService } from 'src/app/services/create.service';
import { GetService } from 'src/app/services/get.service';
import { NotificationsService } from 'src/app/services/notifications.service';


@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {
  registerForm: FormGroup;
  customers = [];

  tallas = false;
  medidas = false;
  verlogos = false;
  logo1: string;
  logo2: string;
  logo3: string;
  logo4: string;
  models = [];
  photo = "";
  des = "";
  disabled = false;
  logo5: string;
  type;


  constructor(private formBuilder: FormBuilder,
    private create_service: CreateService,
    private getservice: GetService,private _routEx:Router, private notification:NotificationsService) {
    this.registerForm = this.formBuilder.group({
      customer: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      model: ["", [Validators.required]],
      colorpri: ["", [Validators.required]],
      colorsec: ["", [Validators.required]],
      colorter: ["", []],
      types: ["", [Validators.required]],
      talla: ["", []],
      cant: [1, []],
      hombro: ["", []],
      brazo: ["", []],
      pecho: ["", []],
      contorno: ["", []],
      largo: ["", []],
      cadera: ["", []],
      logos: ["", []],
      //LOGOS
      logojiba: ["", []],
      logomanga: ["", []],
      logofrente: ["", []],
      logoespalda: ["", []],
      logorh: ["", []],
      logonombre: ["", []],
      logopantalon:["",[]],
      logojibaimagen: ["", []],
      logomangaimagen: ["", []],
      logofrenteimagen: ["", []],
      logoespaldaimagen: ["", []],
      logopantalonimagen:["",[]],
      logorhtext: ["", []],
      logonombretext: ["", []],
      logorhlocation: ["", []],
      logonombrelocation: ["", []],
      observations: ["", []],
      state: ["Registrado", []]

    });
   }

  ngOnInit(): void {
    this.getClientes();
    this.getModels();
    console.log("Console")
  }

  /**
   * getClientes
   */
  public getClientes() {
    let body = {
      "collection": "customers",
      "id": ""
    }
    this.getservice.get(body).subscribe((res:any)=>{
      this.customers = res.response;
      if(localStorage.getItem('user') === 'User'){
        let user:any = localStorage.getItem('data');
        this.registerForm.controls.customer.setValue(user);
        this.type = true;
        this.change(user);
      }
    })
    
  }

    /**
   * getModels
   */
    public getModels() {
      let body = {
        "collection": "models",
        "id": ""
      }
      this.getservice.get(body).subscribe((res:any)=>{
        this.models = res.response;
      })
    }

  /**
   * mostrar
   */
  public mostrar() {
    
  }

  /**
   * registerOutlet
   */
  public registerData(form) {
    console.log(form);
    this.disabled = true;

    if(form.value.customer === ""){
      this.notification.error("Por favor Seleccione un Cliente");
      this.disabled = false; return; 
    }else if (form.value.model === ""){
      this.notification.error("Por favor Seleccione un Modelo");
      this.disabled = false; return; 
    }else if (form.value.colorpri === ""){
      this.notification.error("Por favor Ingrese un Color Principal");
      this.disabled = false; return; 
    }else if (form.value.colorsec === ""){
      this.notification.error("Por favor Ingrese un Color Secundario");
      this.disabled = false; return; 
    }else if (form.value.types === ""){
      this.notification.error("Por favor Ingrese El tipo de Medidas");
      this.disabled = false; return; 
    }else if (form.value.types === "Tallaje" && form.value.talla === ""){
      this.notification.error("Por favor Seleccione una talla.");
      this.disabled = false; return; 
    }else if (form.value.types === "Tallaje" && form.value.cant === ""){
      this.notification.error("Por favor Ingrese una cantidad.");
      this.disabled = false; return; 
    }else if (form.value.types === "Medidas" && form.value.hombro === ""){
      this.notification.error("Por favor Ingrese una medida de Hombro.");
      this.disabled = false; return; 
    }else if (form.value.types === "Medidas" && form.value.brazo === ""){
      this.notification.error("Por favor Ingrese una medida de Brazo.");
      this.disabled = false; return; 
    }else if (form.value.types === "Medidas" && form.value.pecho === ""){
      this.notification.error("Por favor Ingrese una medida de Pecho.");
      this.disabled = false; return; 
    }else if (form.value.types === "Medidas" && form.value.contorno === ""){
      this.notification.error("Por favor Ingrese una medida de Contorno.");
      this.disabled = false; return; 
    }else if (form.value.types === "Medidas" && form.value.largo === ""){
      this.notification.error("Por favor Ingrese una medida de Largo.");
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logojiba === ""){
      this.notification.error("Por favor Seleccione si necesita logo para Jiba.")
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logojiba === "SI" && form.value.logojibaimagen === ""){
      this.notification.error("Por favor Seleccione una imagen para la Jiba");
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logomanga === ""){
      this.notification.error("Por favor Seleccione si necesita logo para Manga.")
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logomanga === "SI" && form.value.logomangaimagen === ""){
      this.notification.error("Por favor Seleccione una imagen para la Manga");
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logofrente === ""){
      this.notification.error("Por favor Seleccione si necesita logo para el Frente.")
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logofrente === "SI" && form.value.logofrenteimagen === ""){
      this.notification.error("Por favor Seleccione una imagen para el Frente");
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logoespalda === ""){
      this.notification.error("Por favor Seleccione si necesita logo para la Espalda.")
      this.disabled = false; return; 
    }else if (form.value.logos === 'Personalizado' && form.value.logoespalda === "SI" && form.value.logoespaldaimagen === ""){
      this.notification.error("Por favor Seleccione una imagen para la Espalada");
      this.disabled = false; return; 
    }
  else if (form.value.logos === 'Personalizado' && form.value.logopantalon === ""){
    this.notification.error("Por favor Seleccione si necesita logo para el Pantalon.")
    this.disabled = false; return; 
  }else if (form.value.logos === 'Personalizado' && form.value.logopantalon === "SI" && form.value.logopantalonimagen === ""){
    this.notification.error("Por favor Seleccione una imagen para el Pantalon");
    this.disabled = false; return; 
  }
    else if (form.value.logorh === ""){
      this.notification.error("Por favor Seleccione si necesita texto de RH")
      this.disabled = false; return; 
    }else if (form.value.logorh === "SI" && form.value.logorhtext === ""){
      this.notification.error("Por favor ingrese el texto RH");
      this.disabled = false; return; 
    }else if (form.value.logorh === "SI" && form.value.logorhtext !== "" && form.value.logorhlocation === ""){
      this.notification.error("Por favor Seleccione una ubicacion de RH");
      this.disabled = false; return; 
    }

    else if (form.value.logonombre === ""){
      this.notification.error("Por favor Seleccione si necesita texto de Nombre.")
      this.disabled = false; return; 
    }else if (form.value.logonombre === "SI" && form.value.logonombretext === ""){
      this.notification.error("Por favor ingrese el texto Nombre");
      this.disabled = false; return; 
    }else if (form.value.logonombre === "SI" && form.value.logonombretext !== "" && form.value.logonombrelocation === ""){
      this.notification.error("Por favor Seleccione una ubicacion de Nombre");
      this.disabled = false; return; 
    }

    let body = {
      "collection":"requests",
      body:form.value
    }
    
    this.create_service.create(body).subscribe((res:any)=>{
      console.log("response", res);
      if(res.success === true){
        if(res.response.acknowledged){
          //this.notification.error("Pedido registrado Correctamente.");
          this.notification.success("Pedido registrado Correctamente.");
          this.disabled = false;
          this._routEx.navigate(['sisproteck-application'])
          this.registerForm.reset();
          if(localStorage.getItem('user') === 'User'){
            let user:any = localStorage.getItem('data');
            this.registerForm.controls.customer.setValue(user);
            this.type = true;
            this.change(user);
          }
        }else{
          this.notification.error("No se logro registrar la solicitud, intenta de nuevo..");
          this.disabled = false;
        }
      }else{
        this.notification.error("No se logro registrar la solicitud, intenta de nuevo..");
        this.disabled = false;
      }
      
    })


  }

  /**
   * change
   */
  public change(value) {
    console.log("changes")
    for (let i = 0; i < this.customers.length; i++) {
    
        if(this.customers[i].name === value ){
          this.registerForm.controls.email.setValue(this.customers[i].email);
          this.registerForm.controls.phone.setValue(this.customers[i].phone);
        }
      
    }
  }


    /**
   * changemodel
   */
    public changemodel(value) {

      this.photo = "";
      this.des = "";
      console.log("changes")
      for (let i = 0; i < this.models.length; i++) {
      
          if(this.models[i].name === value ){
           this.photo = this.models[i].image;
            this.des =  this.models[i].description;
          }
        
      }
    }

  /**
   * change1
   */
  public change1(value) {
    if(value === 'Tallaje'){
      this.tallas = true;
      this.medidas = false;
    }

    if(value === 'Medidas'){
      this.tallas = false;
      this.medidas = true;
    }

    if(value === 'Curva'){
      this.tallas = false;
      this.medidas = false;
    }
  }


    /**
   * change1
   */
    public change2(value) {
      if(value === 'Personalizado'){
        this.verlogos = true;
        
      }else{
        this.verlogos = false;
      }
  
     
    }

  /**
   * uploadFile1
   */
  public uploadFile1(event) {
    let receiptSign = event.target.files[0];
    //event.target.files[0];
    console.log('VIEW');
    let elem = event.target;
    if (elem.files.length > 0) {
      let adjuntoSign = elem.files[0];
      let seleccionado = elem.files[0].name;
      //this.uploadDocumentForValidate();
      const reader = new FileReader();
      reader.readAsDataURL(adjuntoSign);
      reader.onload = () => {
        //console.log(reader.result);
        let base = reader.result.toString();
        this.logo1 = base.replace('data:application/png;base64,', '');
        this.registerForm.controls.logojibaimagen.setValue(this.logo1);
      };
    }
    elem.value = '';
  }

    /**
   * uploadFile1
   */
    public uploadFile2(event) {
      let receiptSign = event.target.files[0];
      //event.target.files[0];
      console.log('VIEW');
      let elem = event.target;
      if (elem.files.length > 0) {
        let adjuntoSign = elem.files[0];
        let seleccionado = elem.files[0].name;
        //this.uploadDocumentForValidate();
        const reader = new FileReader();
        reader.readAsDataURL(adjuntoSign);
        reader.onload = () => {
          //console.log(reader.result);
          let base = reader.result.toString();
          this.logo2 = base.replace('data:application/png;base64,', '');
          this.registerForm.controls.logomangaimagen.setValue(this.logo2);
        };
      }
      elem.value = '';
    }

      /**
   * uploadFile1
   */
  public uploadFile3(event) {
    let receiptSign = event.target.files[0];
    //event.target.files[0];
    console.log('VIEW');
    let elem = event.target;
    if (elem.files.length > 0) {
      let adjuntoSign = elem.files[0];
      let seleccionado = elem.files[0].name;
      //this.uploadDocumentForValidate();
      const reader = new FileReader();
      reader.readAsDataURL(adjuntoSign);
      reader.onload = () => {
        //console.log(reader.result);
        let base = reader.result.toString();
        this.logo3 = base.replace('data:application/png;base64,', '');
        this.registerForm.controls.logofrenteimagen.setValue(this.logo3);
      };
    }
    elem.value = '';
  }

    /**
   * uploadFile1
   */
    public uploadFile4(event) {
      let receiptSign = event.target.files[0];
      //event.target.files[0];
      console.log('VIEW');
      let elem = event.target;
      if (elem.files.length > 0) {
        let adjuntoSign = elem.files[0];
        let seleccionado = elem.files[0].name;
        //this.uploadDocumentForValidate();
        const reader = new FileReader();
        reader.readAsDataURL(adjuntoSign);
        reader.onload = () => {
          //console.log(reader.result);
          let base = reader.result.toString();
          this.logo4 = base.replace('data:application/png;base64,', '');
          this.registerForm.controls.logoespaldaimagen.setValue(this.logo4);
        };
      }
      elem.value = '';
    }


        /**
   * uploadFile5
   */
        public uploadFile5(event) {
          let receiptSign = event.target.files[0];
          //event.target.files[0];
          console.log('VIEW');
          let elem = event.target;
          if (elem.files.length > 0) {
            let adjuntoSign = elem.files[0];
            let seleccionado = elem.files[0].name;
            //this.uploadDocumentForValidate();
            const reader = new FileReader();
            reader.readAsDataURL(adjuntoSign);
            reader.onload = () => {
              //console.log(reader.result);
              let base = reader.result.toString();
              this.logo5 = base.replace('data:application/png;base64,', '');
              this.registerForm.controls.logopantalonimagen.setValue(this.logo5);
            };
          }
          elem.value = '';
        }

}
